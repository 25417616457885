
import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import Twitter from '@hackernoon/pixel-icon-library/icons/SVG/brands/twitter.svg';

import HomepageHero from './HomepageHero';
import MarioBox from './MarioBox';


function App() {

  const navRef = useRef();
  const [isNavSticky, setIsNavSticky] = useState(false);
  const onAppScroll = (e) => {
    const element = navRef.current;
    if (!element) return;

    const rect = element.getBoundingClientRect();

    setIsNavSticky(rect.top <= 100);
  }

  const [override, setOverride] = useState(null);

  // Check if nav sticky on first load
  useEffect(onAppScroll, [])

  return (
    <div className="App" onScroll={onAppScroll}>
      <HomepageHero />
      <nav ref={navRef} className={isNavSticky ? 'sticky' : ''}>
        <div className="content">
          <a href="/" className="title">
            <span>
              big
            </span>
            <span>
              TEAM
            </span>
          </a>
        </div>
      </nav>
      <main>
        <Secret override={(o) => setOverride(o)} />
        <MarioBox style={{ minHeight: '100vh' }}>
          <div className="container" style={{ minHeight: '100vh' }}>
            <div className="box" style={{ maxWidth: '40rem' }}>
              {override ? override : (
                <>
                  <h1>We're building something big</h1>
                  <p style={{ color: 'white', opacity: '0.75' }}>
                    Friends of friends of friends who make games
                  </p>
                  <p style={{ color: 'white', opacity: '0.75' }}>
                    Email us at:
                    {' '}
                    <a
                      style={{ color: 'yellow', opacity: '0.75', textDecoration: 'underline' }}
                      href="mailto:contact@bigteam.gg"
                    >
                      contact@bigteam.gg
                    </a>
                  </p>
                  <p style={{ color: 'white', opacity: '0.75' }}>
                    <a
                      style={{ color: 'yellow', opacity: '0.75', textDecoration: 'underline' }}
                      href="https://twitter.com/bigTEAMvg"
                    >
                      Twitter
                    </a>
                  </p>
                  <p>
                    <a
                      style={{ color: 'yellow', opacity: '0.75', textDecoration: 'underline' }}
                      href="https://bigteamvg.itch.io"
                    >
                      Itch
                    </a>
                  </p>
                </>
              )}
              <img
                style={{ width: '2rem', aspectRatio: '1/1', filter: 'opacity(0.5)' }}
                src="logo192.png"
                alt="icon"
              />
            </div>
          </div>
        </MarioBox>
      </main>
      {/* <HorizontalBannerRuling /> */}
      <footer style={{ display: 'none' }}>
      </footer>
    </div>
  );
}

const Secret = (props) => {
  const searchChar = window.location.search?.charAt(1).toLowerCase();
  const team = (searchChar === 'r' ? 'red' : (searchChar === 'b' ? 'blue' : null));
  const [secretOn, setSecretOn] = useState(false);

  useEffect(() => {
    if (!team) {
      localStorage.removeItem('r'); // top right
      localStorage.removeItem('r2'); // top right
      localStorage.removeItem('r3'); // top right
      localStorage.removeItem('b'); // top left
      localStorage.removeItem('b2'); // top left
      localStorage.removeItem('b3'); // top left
      return () => { };
    }

    const id = setInterval(() => {
      if (team === 'red') {
        localStorage.setItem('r', window.screenX + window.innerWidth - 12) // top right
        localStorage.setItem('r2', window.screenY) // top right
        localStorage.setItem('r3', window.innerHeight) // top right
      } else if (team === 'blue') {
        localStorage.setItem('b', window.screenX) // top left
        localStorage.setItem('b2',  window.screenY) // top left
        localStorage.setItem('b3',  window.innerHeight) // top left
      }

      const topMatches = Math.abs(localStorage.getItem('r') - localStorage.getItem('b')) < 25;
      const bottomMatches = Math.abs(localStorage.getItem('r2') - localStorage.getItem('b2')) < 25;
      const heightMatches = Math.abs(localStorage.getItem('r3') - localStorage.getItem('b3')) < 25;

      setSecretOn(topMatches && bottomMatches && heightMatches);
    }, 1000.0 / 60.0);

    return () => clearInterval(id);
  }, [team]);

  useEffect(() => {
    if (!secretOn) return;
    window.canvas = document.getElementById("world");
    if (!window.canvas) return;
    window.context = window.canvas.getContext("2d");
    resizeWindow()
    localStorage.setItem('xpos', 0.5);

    const pattern = document.querySelector('.pattern');
    pattern.style.animation = 'none';
    // eslint-disable-next-line no-unused-expressions
    pattern.offsetHeight; /* trigger reflow */
    pattern.style.animation = null;
    
    window.requestAnimationFrame(() => {
      const box = document.querySelector('.box')
      box.style = 'position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); transition: left 0.5s; min-width: 30rem; max-width: 30rem;';
      props.override(
        <>
          <h1>Hell yeah!
            <br />
            You're super cool!
          </h1>
          <p>Come join us
            {' '}
            <a href={atob('aHR0cHM6Ly9kaXNjb3JkLmdnL3c2akV0akd1S0E=')}>
              on discord
            </a>
            !
          </p>
        </>
      );
      window.requestAnimationFrame(() => {
        if (team === 'red') {
          box.style = 'position: absolute; top: 50%; left: 100%; transform: translate(-50%, -50%); transition: left 0.5s; min-width: 30rem; max-width: 30rem;';
        } else {
          box.style = 'position: absolute; top: 50%; left: 0%; transform: translate(-50%, -50%); transition: left 0.5s; min-width: 30rem; max-width: 30rem;';
        }
      });
    });

    return () => {
      window.canvas = null;
      window.context = null;
      const box = document.querySelector('.box')
      box.style = '';
      props.override(null);
    }
  }, [secretOn])

  if (!team) return null;

  return (
    <>
      {secretOn ? <canvas id="world" style={{ position: 'fixed', inset: 0 }}></canvas> : null}
      <div className={`secret ${team}`}>

      </div>
    </>
  );
}

const NUM_CONFETTI = 350;
const COLORS = [[85, 71, 106], [174, 61, 99], [219, 56, 83], [244, 92, 68], [248, 182, 70]];
const PI_2 = 2 * Math.PI;

window.w = 0;
window.h = 0;

function resizeWindow() {
  if (!window.canvas) return;
  window.w = window.canvas.width = window.innerWidth;
  window.h = window.canvas.height = window.innerHeight;
}

window.addEventListener('resize', resizeWindow, false);

window.onload = function () {
  setTimeout(resizeWindow, 0);
};

function range(a, b) {
  return (b - a) * Math.random() + a;
}

function drawCircle(x, y, r, style) {
  if (!window.context) return;
  window.context.beginPath();
  window.context.arc(x, y, r, 0, PI_2, false);
  window.context.fillStyle = style;
  window.context.fill();
}

localStorage.setItem('xpos', 0.5);

document.onmousemove = function (e) {
  const searchChar = window.location.search?.charAt(1).toLowerCase();
  const team = (searchChar === 'r' ? 'red' : (searchChar === 'b' ? 'blue' : null));
  if (team === 'red') {
    localStorage.setItem('xpos', (e.pageX / window.w) / 2);
  } else if (team === 'blue') {
    localStorage.setItem('xpos', (e.pageX / window.w) / 2 + 0.5);
  }
};

window.requestAnimationFrame = (function () {
  return window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    };
})();

class Confetti {
  constructor() {
    this.style = COLORS[Math.floor(range(0, 5))];
    this.rgb = `rgba(${this.style[0]},${this.style[1]},${this.style[2]}`;
    this.r = Math.floor(range(2, 6));
    this.r2 = 2 * this.r;
    this.replace();
  }

  replace() {
    this.opacity = 0;
    this.dop = 0.03 * range(1, 4);
    this.x = range(-this.r2, window.w - this.r2);
    this.y = range(-20, window.h - this.r2);
    this.xmax = window.w - this.r;
    this.ymax = window.h - this.r;
    this.vx = range(0, 2) + 8 * localStorage.getItem('xpos') - 5;
    this.vy = 0.7 * this.r + range(-1, 1);
  }

  draw() {
    this.x += this.vx;
    this.y += this.vy;
    this.opacity += this.dop;

    if (this.opacity > 1) {
      this.opacity = 1;
      this.dop *= -1;
    }

    if (this.opacity < 0 || this.y > this.ymax) {
      this.replace();
    }

    if (!(0 < this.x && this.x < this.xmax)) {
      this.x = (this.x + this.xmax) % this.xmax;
    }

    drawCircle(Math.floor(this.x), Math.floor(this.y), this.r, `${this.rgb},${this.opacity})`);
  }
}

const confetti = Array.from({ length: NUM_CONFETTI }, () => new Confetti());

window.step = function () {
  requestAnimationFrame(window.step);
  if (!window.context) return;
  window.context.clearRect(0, 0, window.w, window.h);
  confetti.forEach(c => c.draw());
};

window.step();

const HorizontalBannerRuling = (props) => {
  const { title, bannerStyle } = props;

  return (
    <div className="strips">
      <div className="strip" style={{ backgroundColor: 'brown' }}>

      </div>
      <div className="strip" style={{ backgroundColor: 'green' }}>
        {title}
      </div>
      <div className="strip" style={{ backgroundColor: 'skyblue' }}>

      </div>
    </div>
  );
}

export default App;
